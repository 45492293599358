$colorWhite: #ffffff;
$colorGrayLight: #edebe9;
$colorGray: #dddbd9;
$colorBlack: #000000;
$colorBlue: #2F80ED;
$colorBlueLight: #6ec6ff;
$colorBlueDark: #004f8f;
$colorGreen: #1b5e20;
$colorGreenLight: #4c8c4a;
$colorGreenLightest: #c4dac3;
$colorGreenDark: #00251a;
$colorCoral: #ff7043;
$colorCoralLight: #FFB7A1;
$bodyFont: "mallory-compact",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
$navFont: "mallory-compact",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
$headingFont: "mallory-condensed",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
$baseUnit: 8;
$baseFontSize: 16px;
$screenBig: 880px;

*,
*:before,
*:after {
  box-sizing: inherit;
}

::selection {
  background-color: $colorGrayLight;
}

body {
  background: $colorGrayLight;
  color: $colorBlack;
  font-family: $bodyFont;
  font-size: $baseFontSize;
  margin: 0 auto;
}

// Layout

.element__thin {
  margin: 0 auto;
  max-width: 720px;
}

.element__wide {
  margin: 0 auto;
  max-width: 1120px;
}

.site__header,
.site__main {
  padding: 2rem;
  margin: 0 auto;
}

.site__nav {
  margin: 0 auto;
  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  li {
    margin-right: 2rem;
    &:last-child {
      margin-right: 0;
    }

  }
  a {
    display: inline-block;
    font-family: $navFont;
    line-height: 1.5rem;
    text-decoration: none;
    transition: all .5s ease;
    &:link,
    &:visited {
      color: $colorBlack;
    }
    &:hover {
      color: transparentize($colorBlack, .5);
    }
  }
}

.page__home {
  background: $colorWhite;
  color: $colorGreenDark;
  main {
    a {
      background-size: 0 100%;
      background-repeat: no-repeat;
      line-height: 1.75rem;
      text-decoration: none;
      transition: background-size .5s ease;
    }
    .one {
        color: $colorGreen;
        background-image: linear-gradient(180deg,transparentize($colorGreenLight, .75),transparentize($colorGreenLight, .75));
        border-bottom: 2px solid $colorGreenLightest;
        &:hover {
          background-size: 100% 100%;
          border-bottom: 2px solid $colorGreenLight;
        }
      }
    .two {
      color: $colorBlue;
      background-image: linear-gradient(180deg,transparentize($colorBlueLight, .75),transparentize($colorBlueLight, .75));
      border-bottom: 2px solid $colorBlueLight;
      &:hover {
        background-size: 100% 100%;
        border-bottom: 2px solid $colorBlue;
      }
    }
    .three {
      color: $colorCoral;
      background-image: linear-gradient(180deg,transparentize($colorCoralLight, .75),transparentize($colorCoralLight, .75));
      border-bottom: 2px solid $colorCoralLight;
      &:hover {
        background-size: 100% 100%;
        border-bottom: 2px solid $colorCoral;
      }
    }
  }

  .site__nav {
    .logo {
      fill: $colorGreenDark;
    }
    a {
      color: $colorGreen;
      &:hover {
        color: transparentize($colorGreenLightest, .5);
      }
    }
  }
}

.page__about {
  background: $colorCoral;
  color: $colorBlack;
}

.page__work {
  background: $colorGrayLight;
}

.page__library {
  background: $colorCoral;
  color: $colorBlack;
  .site__nav {
    .logo {
      fill: $colorBlack;
    }
    a {
      color: $colorBlack;
      &:hover {
        color: transparentize($colorBlack, .5);
      }
    }
  }
}

.page__blog {
  background: $colorBlueDark;
  color: $colorWhite;
}

.page__conversational-cloud,
.page__keku,
.page__helpothersio {
  background: $colorGrayLight;
  color: $colorBlack;
}

.page__about .nav__about,
.page__work .nav__work,
.page__library .nav__library {
  &:link, &:active, &:visited, &:hover {
    color: $colorBlack;
    border-bottom: 2px solid $colorBlack;
  }
}

.page__blog .nav__blog {
  &:link, &:active, &:visited, &:hover {
    color: $colorWhite;
    border-bottom: 2px solid $colorWhite;
  }
}

.portrait {
  //margin-bottom: 4rem;
}

.h1,
.h2,
.page__heading,
.page__subheading {
  font-family: $headingFont;
  font-weight: 600;
}

.h1,
.page__heading {
  font-size: 2.5rem;
  letter-spacing: -.025rem;
  line-height: 3rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.h2 {
  font-size: 1.25rem;
  margin-bottom: .5rem;
}

.page__subheading {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

p {
  line-height: 1.5rem;
  margin-bottom: 3rem;
}

.library {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(192px, 1fr));
  grid-gap: 3rem;
  .book {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    .book__cover {
      min-height: 16rem;
      position: relative;
      margin-bottom: 1rem;
      img {
        position: absolute;
        bottom: 0;
      }
    }
    .book__title {
      font-family: $headingFont;
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin-bottom: .5rem;
    }
  }
  img {
    max-width: 100%;
  }
}

.projects {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(384px, 1fr));
  grid-gap: 3rem;
}

.project__link {
  text-decoration: none;
}

.project__card {
  display: flex;
  flex-flow: column;
}

.project__media {
  overflow: hidden;
  margin-bottom: 1rem;
  &:hover {
    .project__cover img {
        transform: scale(1.05);
    }
  }
}

.project__tags {
  margin-top: 1rem;
  li {
    background: transparentize($colorBlueLight,.75);
    display: inline-block;
    text-transform: lowercase;
    padding: .333rem .5rem;
    border-radius: .25rem;
    font-size: .875rem;
    color: $colorBlueDark;
  }
}

.project__cover img {
  display: block;
  max-width: 100%;
  transition: transform .5s ease;
}

.page__cover {
  max-width: 100%;
  margin-bottom: 4rem;
}

.project__client {
  color: transparentize($colorBlack, .5);
  font-family: $navFont;
  font-size: .875rem;
  letter-spacing: .0125rem;
  margin-bottom: .5rem;
}

.project__heading {
  color: $colorBlack;
  font-family: $headingFont;
  font-size: 1.25rem;
  line-height: 1.5rem;
  //margin-bottom: 4rem;
}

.two-up {
  margin-bottom: 4rem;
}

@media (min-width: $screenBig){
  .two-up {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    margin-bottom: 2rem;
    .left {
      width: 33.333%;
    }
    .right {
      width: 66.666%;
    }
  }
}

.post-list {
  li {
    &:last-child {
      border-bottom: 0;
    }
    border-bottom: 1px solid transparentize($colorBlack, .75);
    a {
      display: flex;
      align-items: baseline;
      padding: 4rem 0;
      color: $colorBlack;
      text-decoration: none;
      &:hover {
        h2 {
          color: $colorGrayLight;
          transition: color .5s ease;
        }
      }
      span {
        width: 8rem;
        font-family: $navFont;
        font-size: .875rem;
        color: transparentize($colorBlack, .5);
      }
    }
  }
}

.post {
  h1,h2,h3,p {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
  h1,h2,h3 {
    font-family: $headingFont;
    margin-bottom: 1rem;
  }
  h1 {
    margin-bottom: 4rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.25rem;
    margin-top: 4rem;
  }
  .post__date {
    font-family: $navFont;
  }
  figure {
    margin: 4rem auto;
    figcaption {
      color: transparentize($colorBlack, .5);
      font-style: italic;
      font-size: .75rem;
      line-height: 1.25rem;
      margin-top: 1rem;
    }
  }
  img {
    width: 100%;
  }
  a {
    color: $colorBlack;
    background-image: linear-gradient(180deg,transparentize($colorBlue, .75),transparentize($colorBlue, .75));
    background-size: 0 100%;
    background-repeat: no-repeat;
    border-bottom: 2px solid $colorBlack;
    text-decoration: none;
    transition: background-size .5s ease;
    &:hover {
      background-size: 100% 100%;
      border-bottom: 2px solid $colorBlue;
    }
  }
  hr {
    margin: 6rem auto;
    height: 1px;
    border: 0;
    border-top: 1px solid transparentize($colorBlack, .875);
    width: 16%;
  }
  em {
    font-style: italic;
  }
  blockquote {
    p {
      font-size: 1.25rem;
      text-indent: -.65rem;
      line-height: 1.875rem;
      background: transparentize($colorBlueLight,.9);
      padding: 2rem;
      em {
        font-family: $navFont;
        font-style: normal;
        font-size: .875rem;
      }
    }
  }
}

.page__project {
  img {
  }
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  figure {
    height: 40vh;
    flex: 1 0 auto;
      img {
        max-height: 100%;
        min-width: 100%;
        object-fit: cover;
        vertical-align: bottom;
    }
  }
}

.portrait {
  max-width: 33%;
}

.visible-mobile {
  display: static;
}

@media (min-width: $screenBig){
  .visible-mobile {
    display: none;
  }
  .site__main {
    padding:0;
  }
  .portrait {
    max-width: 66%;
  }
  .row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
    .col__one-third,
    .col__two-thirds, {
      
    }
    .col__one-third {
      width:33%;
    }
    .col__two-thirds {
      width: 66%;
    }
  }
}